<template>
    <v-app>
        <v-container>
            <v-card color="white" class="pa-2 no_border reports_list pt-6" elevation="0">
                <v-row>
                    <v-col sm="12" cols="12" class="py-0">
                        <h2 class="mb-0 font_20">{{ $t('setting') }}</h2>
                        <v-icon 
                            onclick="window.history.go(-1); return false;" 
                            style="cursor: pointer; color: #333; font-size: 40px;"
                            class="float-right"
                        >close</v-icon>
                    </v-col>
                    <v-col sm="12" cols="12">
                        <v-tabs vertical class="tab_setting tab_product_service_show" slider-color="grayBg" slider-size="7">
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('general') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('customer') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('product_categories') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('payment_options') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('session_setting') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('sale_unit_item') }}
                                </span>
                            </v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('sale_channel') }}
                                </span>
                            </v-tab>
                            
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <v-card color="white" class=" no_border reports_list" elevation="0" style="height: 750px;overflow-y: scroll;overflow-x: clip;">
                                            <General/>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <Customer/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                         <v-card color="white" class=" no_border reports_list" elevation="0" style="height: 750px;overflow-y: scroll;overflow-x: clip;">
                                            <Categories/>
                                         </v-card>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <PaymentOptions/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                      <Session/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <SaleUnitItemsTab/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <SaleChannel/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data: () => ({}),
    props: {},
    methods: {
        clickMe(data) {
            // alert(data.link)
            this.$router.push(`${data.link}`);
            //this.$event.target.classList.toggle(active)
            //eslint-disable-next-line no-console
            console.log(data.link)
            //eslint-disable-next-line no-console
            //console.log(data)
        }
    },
    components: {
        General: () => import('./general/General'),
        PaymentOptions: () => import('./PaymentOptions'),
        SaleChannel: () => import('./SaleChannel'),
        SaleUnitItemsTab: () => import('./sale_unit_items/SaleUnitItemsTab'),
        Session: () => import('./Session'),
        Categories: () => import('./Categories'),
        Customer: () => import('./Customer'),


    }
};
</script>
<style scoped>
.v-tab {
    justify-content: left;
    font-size: 16px;
}

.v-tab--active {
    background-color: rgb(255, 255, 255);
}

.tab_setting .v-tab--active {
    font-weight: 700;
    color: #000;
}

.v-tab--active {
    background-color: #ffffff !important;
    border-bottom: 4px solid #92d050;
    border-left: none;
}

.v-tabs-slider {
    background-color: #EDF1F5 !important;
    padding-left: 6px;
    height: 100%;
    width: 100%;
}

@media (max-width: 576px) {

}
</style>